import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getImgixUrl } from '../../../../helpers';
import { useRootSelector } from '../../../../store/hooks';
import { selectPageConfig } from '../../../../store/features/pageConfig';
import { selectCoursePricing } from '../../../../store/features/coursePricing';
import { CourseOptionDisplayModuleHeroSection } from './types';
import { SiteBuilderModule } from '../sharedComponents';
import { ModuleWrapper, OverlayWrapper } from '../HeroModule/styles';
import { ImageDisplayContent } from '../HeroModule/displayStyles';
import { CardsList } from './dataComponents';

export type CourseOptionDisplayHeroModuleProps = {
  section: CourseOptionDisplayModuleHeroSection;
};

export default function CourseOptionDisplayHeroModule(
  props: CourseOptionDisplayHeroModuleProps,
) {
  const {
    section: {
      shown,
      opacityType,
      background,
      displayType,
      colorType,
      textAlignment: alignStyle,
      optionsDisplay,
    },
  } = props;

  const themeConfig = useContext(ThemeContext);

  const {
    coursePricing,
    pageConfig: { tenantId },
  } = useRootSelector((state) => ({
    coursePricing: selectCoursePricing(state),
    pageConfig: selectPageConfig(state),
  }));

  if (!shown || !coursePricing.loaded) {
    return null;
  }

  const { courseOptionDisplayHeroModule } = themeConfig.siteBuilderModules;
  const vStyles =
    courseOptionDisplayHeroModule.visualStyles[displayType][colorType];
  const dStyles = courseOptionDisplayHeroModule.displayStyles[displayType];
  const optionsDStyles =
    courseOptionDisplayHeroModule.optionsDisplayStyles[displayType];
  const optionsVStyles =
    courseOptionDisplayHeroModule.optionsVisualStyles[displayType][
      optionsDisplay.style
    ];

  const backgroundPath = getImgixUrl(tenantId, background?.path);

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{
        dStyles,
        vStyles,
        optionsDStyles,
        optionsVStyles,
        opacityType,
        alignStyle,
      }}
    >
      <ModuleWrapper
        data-background-image={backgroundPath}
        className="lazy"
        data-testid="course-option-display-hero-module-wrapper"
      >
        <OverlayWrapper />
        <ImageDisplayContent
          section={props.section}
          contentBlock={<CardsList optionsDisplay={optionsDisplay} />}
        />
      </ModuleWrapper>
    </SiteBuilderModule>
  );
}
