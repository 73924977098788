import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { CardText } from '../CardText';

export const CardBoldText = styled(CardText)`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsVStyles?.elements?.boldText,
    )};
`;
