import { CoursePricingOption } from '../../../../../../store/features/coursePricing';
import {
  CourseOptionAllFilterOption,
  CourseOptionFilterType,
} from '../../constants';
import { CourseOptionDisplayModuleSelectedFilters } from '../../context';

export function getFilteredRows(
  rows: CoursePricingOption[],
  selectedFilters: CourseOptionDisplayModuleSelectedFilters,
) {
  const filterKeys = Object.keys(selectedFilters) as CourseOptionFilterType[];

  return filterKeys.reduce((accRows, filterKey) => {
    if (selectedFilters[filterKey] === CourseOptionAllFilterOption) {
      return accRows;
    }

    return accRows.filter(
      (row) => row[filterKey] === selectedFilters[filterKey],
    );
  }, rows);
}
