import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const CardsWrapper = styled.div`
  position: relative;
  z-index: 999;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    margin-top: 32px;
  }
`;
