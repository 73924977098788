import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import { CallToAction } from '../../../sharedComponents';
import { ModuleCallToAction } from '../../../types';

const CallToActionWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsDStyles?.elements?.callToAction,
    )};

  a {
    display: block;
  }
`;

export type CardsCallToActionProps = {
  ctaConfig?: ModuleCallToAction;
};

export const CardsCallToAction = ({ ctaConfig }: CardsCallToActionProps) => {
  const themeConfig = useContext(ThemeContext);
  const optionsDStyles = themeConfig.module.optionsDStyles;
  const { size } = optionsDStyles.elements?.callToAction?.settings || {};

  return (
    <CallToActionWrapper>
      <CallToAction size={size} ctaConfig={ctaConfig} />
    </CallToActionWrapper>
  );
};
