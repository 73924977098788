import i18next from 'i18next';

export function getOptionDurationValue(
  shown: boolean,
  value?: number,
  override?: string,
) {
  if (!shown) return '';
  if (override) return override;

  if (value) {
    return i18next.t(
      'site.modules.course-option-display.table.column.amount-of-days.label',
      { 0: value },
    );
  }

  return '';
}
