import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledCardsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsVStyles?.elements?.list,
    )}
`;
