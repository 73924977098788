import React from 'react';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import { StyledLink } from '../../styledComponents';

export type TrainerProps = {
  displayName: string;
  uri: string;
};

export function Trainer(props: TrainerProps) {
  const { uri, displayName } = props;

  const { sitePath } = useRootSelector(selectPageConfig);
  const trainerLink = `${sitePath}/trainers/${uri}`;

  return <StyledLink to={trainerLink} label={displayName} />;
}
