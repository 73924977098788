import styled from 'styled-components';
import classNames from 'classnames';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledIcon = styled.i`
  color: inherit;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.optionsVStyles.elements.icon)}
`;

export type IconProps = {
  matIcon: string;
  outlined?: boolean;
};

export const CardIcon = ({ matIcon, outlined }: IconProps) => {
  return (
    <StyledIcon
      className={classNames({
        'material-icons': !outlined,
        'material-icons-outlined': outlined,
      })}
    >
      {matIcon}
    </StyledIcon>
  );
};
