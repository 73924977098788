import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
`;

export const FlexRowCenter = styled(FlexRow)`
  align-items: center;
`;

export const FlexRowWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;
