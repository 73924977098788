import { createSelector } from '@reduxjs/toolkit';
import {
  selectCoursePricing,
  CoursePricingOption,
} from '../../../../../../../store/features/coursePricing';

export const selectCoursePricingOptions = createSelector(
  [selectCoursePricing, (state, section) => section],
  (coursePricing, optionsDisplay = {}) => {
    const {
      filters: {
        deliveryTypes = [],
        languages = [],
        currencies = [],
        trainerIds = [],
      } = {},
      numberOfCoursesToShow,
    } = optionsDisplay;

    const filterByDeliveryType = (options: CoursePricingOption[]) => {
      if (deliveryTypes.length === 0) {
        return options;
      }

      return options.filter((option) => {
        return deliveryTypes.includes(option.deliveryType);
      });
    };

    const filterByLanguage = (options: CoursePricingOption[]) => {
      if (languages.length === 0) {
        return options;
      }

      return options.filter((option) => {
        return languages.includes(option.language);
      });
    };

    const filterByCurrency = (options: CoursePricingOption[]) => {
      if (currencies.length === 0) {
        return options;
      }

      return options.filter((option) => {
        return currencies.includes(option.currency);
      });
    };

    const filterByTrainer = (options: CoursePricingOption[]) => {
      if (trainerIds.length === 0) {
        return options;
      }

      return options.filter((option) => {
        const optionTrainers = option.trainers || [];

        return trainerIds.every((trainerId: string) => {
          const foundTrainer = optionTrainers.find(
            (trainer) => trainer.id === trainerId,
          );

          return Boolean(foundTrainer);
        });
      });
    };

    // Filter course options by pre-selected filters
    const filteredOptions = [
      filterByDeliveryType,
      filterByLanguage,
      filterByCurrency,
      filterByTrainer,
    ].reduce((options, filterByFunc) => {
      return filterByFunc(options);
    }, coursePricing.options || []);

    // If a number of courses to show is set, return that number of courses
    if (numberOfCoursesToShow) {
      const coursesToShow = parseInt(numberOfCoursesToShow, 10);

      return filteredOptions.slice(0, coursesToShow);
    }

    return filteredOptions;
  },
);
