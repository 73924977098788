import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DAY_MONTH,
  DeliveryType,
  MONTH_DAY,
} from '../../../../../../constants';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectSiteHeaderData } from '../../../../../../store/features/siteHeader';
import { CoursePricingOption } from '../../../../../../store/features/coursePricing';
import { getCorrectLocale } from '../../../../../../helpers';
import { getDeliveryTypeName } from '../../../../../../helpers/getDeliveryTypeName';
import { getLanguageName } from '../../../../../../helpers/getLanguageName';
import {
  FlexRow,
  FlexRowCenter,
  FlexRowWrap,
  CardBoldText,
  CardThinText,
  StyledCard,
  TextWithIcon,
  CardIcon,
} from '../../styledComponents';
import { Trainer } from '../Trainer';

export type CardProps = {
  option: CoursePricingOption;
};

export function Card({ option }: CardProps) {
  const {
    startDate,
    dateAsText,
    deliveryType,
    trainers,
    amountOfDays,
    numberOfDaysOverride,
    language,
    location,
  } = option;

  const { i18n, t } = useTranslation();
  const { dateFormat = DAY_MONTH } = useRootSelector(selectSiteHeaderData);

  const momentLocale = getCorrectLocale(i18n.language);
  const date = startDate
    ? moment(startDate, ['YYYY-MM-DD'])
        .locale(momentLocale)
        .format(dateFormat === MONTH_DAY ? 'MMMM D, YYYY' : 'D MMMM YYYY')
    : dateAsText;

  const isOnDemand =
    deliveryType === DeliveryType.ON_DEMAND ||
    deliveryType === DeliveryType.FLEXI;
  const deliveryTypeName = getDeliveryTypeName(deliveryType);

  const { city, cityAsText, country, venueName } = location || {};
  const cityInfo = [cityAsText || city, country].filter(Boolean);
  const locationValue = [...cityInfo, venueName].filter(Boolean).join(', ');

  return (
    <StyledCard>
      <FlexRowCenter>
        {isOnDemand ? (
          <CardBoldText text={date || deliveryTypeName} />
        ) : (
          <>
            <CardBoldText text={date} />
            <CardThinText text=" | " />
            <CardThinText text={deliveryTypeName} />
          </>
        )}
      </FlexRowCenter>
      {trainers?.length > 0 && (
        <FlexRow>
          <CardIcon matIcon="person" outlined />
          <FlexRowWrap>
            {trainers.map((trainer, index) => {
              const isLast = index === trainers.length - 1;
              const displayName = `${trainer.displayName}${isLast ? '' : ','}`;

              return (
                <Trainer
                  key={trainer.id}
                  displayName={displayName}
                  uri={trainer.uri}
                />
              );
            })}
          </FlexRowWrap>
        </FlexRow>
      )}
      <FlexRow>
        {language && (
          <TextWithIcon
            matIcon="language"
            outlined
            text={getLanguageName(language)}
          />
        )}
        {numberOfDaysOverride && !isOnDemand && (
          <TextWithIcon
            matIcon="schedule"
            outlined
            text={numberOfDaysOverride}
          />
        )}
        {amountOfDays && !numberOfDaysOverride && !isOnDemand && (
          <TextWithIcon
            matIcon="schedule"
            outlined
            text={t(
              'site.modules.course-option-display-hero.list.item.amount-of-days.label',
              { 0: amountOfDays },
            )}
          />
        )}
      </FlexRow>
      {locationValue && !isOnDemand && (
        <TextWithIcon matIcon="maps_home_work" outlined text={locationValue} />
      )}
    </StyledCard>
  );
}
