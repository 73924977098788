import i18next from 'i18next';
import { CourseOptionFilter } from '../types';
import { getDeliveryTypeName } from '../../../../../helpers/getDeliveryTypeName';
import { getLanguageName } from '../../../../../helpers/getLanguageName';
import { getCurrencyName } from '../../../../../helpers/getCurrencyName';

export enum CourseOptionDeliveryTypes {
  IN_PERSON = 'IN_PERSON',
  LIVE_ONLINE = 'LIVE_ONLINE',
  ON_DEMAND = 'ON_DEMAND',
  FLEXI = 'FLEXI',
  ONLINE_PROGRAMME = 'ONLINE_PROGRAMME',
}

export enum CourseOptionLanguages {
  ARABIC = 'ARABIC',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  PORTUGUESE = 'PORTUGUESE',
  SPANISH = 'SPANISH',
  VIETNAMESE = 'VIETNAMESE',
}

export enum CourseOptionCurrencies {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
  AED = 'AED',
  CHF = 'CHF',
  AUD = 'AUD',
  SAR = 'SAR',
}

export enum CourseOptionLoadMoreButtonShownAfter {
  OFF = 'OFF',
  FIVE = 'FIVE',
  THREE = 'THREE',
  TEN = 'TEN',
}

export const CourseOptionShowMoreAfterMapper: {
  [key: string]: number | undefined;
} = {
  [CourseOptionLoadMoreButtonShownAfter.THREE]: 3,
  [CourseOptionLoadMoreButtonShownAfter.FIVE]: 5,
  [CourseOptionLoadMoreButtonShownAfter.TEN]: 10,
};

export enum CourseOptionFilterType {
  LANGUAGE = 'language',
  DELIVERY_TYPE = 'deliveryType',
  CURRENCY = 'currency',
}

export const CourseOptionAllFilterOption = 'ALL';

export const deliveryTypeFilters: CourseOptionFilter[] = [
  {
    value: CourseOptionAllFilterOption,
    label: i18next.t(
      'site.modules.course-option-display.menu.item.all.locations.label',
    ),
  },
  ...Object.values(CourseOptionDeliveryTypes).map((deliveryType) => ({
    value: deliveryType,
    label: getDeliveryTypeName(deliveryType),
  })),
];

export const languageFilters: CourseOptionFilter[] = [
  {
    value: CourseOptionAllFilterOption,
    label: i18next.t(
      'site.modules.course-option-display.menu.item.all.languages.label',
    ),
  },
  ...Object.values(CourseOptionLanguages).map((language) => ({
    value: language,
    label: getLanguageName(language),
  })),
];

export const currencyFilters: CourseOptionFilter[] = [
  {
    value: CourseOptionAllFilterOption,
    label: i18next.t(
      'site.modules.course-option-display.menu.item.all.currencies.label',
    ),
  },
  ...Object.values(CourseOptionCurrencies).map((currency) => ({
    value: currency,
    label: getCurrencyName(currency),
  })),
];
