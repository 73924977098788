import { createSelector } from '@reduxjs/toolkit';

export type CoursePricingOptionTrainer = {
  displayName: string;
  id: string;
  uri: string;
};

export type CoursePricingOptionLocation = {
  city?: string;
  cityAsText?: string;
  country?: string;
  venueName?: string;
  shownInHeader?: boolean;
};

export type CoursePricingBrochure = {
  file?: {
    filename?: string;
    lastUpdated?: string;
    sourceFilename?: string;
  };
  ctaUrl?: string;
  ctaText?: string;
  openInNewTab?: boolean;
};

export type CoursePricingOptionTier = {
  endDate?: string;
  maxSaving?: string;
};

export type CoursePricingOption = {
  bookingLinkOverride?: string;
  bookingButtonTextOverride?: string;
  bookingClosedText?: string;
  bookingOpen: boolean;
  brochureCtaUrl?: string;
  brochureCtaText?: string;
  deliveryType: string;
  language: string;
  openBrochureInNewTab: boolean;
  time?: string;
  timeZone?: string;
  timeZoneAsText?: string;
  trainers: CoursePricingOptionTrainer[];
  currency: string;
  startDate: string;
  dateAsText?: string;
  numberOfDaysOverride?: string;
  salesMessage?: string;
  amountOfDays: number;
  primaryProductDiscountedPrice?: string;
  primaryProductFullPrice?: string;
  location?: CoursePricingOptionLocation;
  tier?: CoursePricingOptionTier;
  index?: number;
};

export type CoursePricing = {
  loaded?: boolean;
  loading?: boolean;
  courseBrochure?: CoursePricingBrochure;
  options: CoursePricingOption[];
};

export const selectCoursePricing = createSelector(
  [(state) => state.coursePricing as CoursePricing],
  (data) => data,
);
