import React from 'react';
import styled from 'styled-components';
import { IconProps, CardIcon } from '../CardIcon';
import { CardText } from '../CardText';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export type TextWithIconProps = {
  text?: string;
} & IconProps;

export const TextWithIcon = ({
  matIcon,
  outlined,
  text,
}: TextWithIconProps) => {
  return (
    <Wrapper>
      <CardIcon matIcon={matIcon} outlined={outlined} />
      <CardText text={text} />
    </Wrapper>
  );
};
