import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledText = styled.span`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsVStyles?.elements?.text,
    )};
`;

export type CardTextProps = {
  text?: string | null;
};

export const CardText = ({ text, ...rest }: CardTextProps) => {
  return <StyledText {...rest}>{text}</StyledText>;
};
