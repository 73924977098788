export type GetOptionTimeValueProps = {
  showTime: boolean;
  showTimezone: boolean;
  time?: string;
  timeZone?: string;
  timeZoneAsText?: string;
};

export function getOptionTimeValue(props: GetOptionTimeValueProps) {
  const { showTime, showTimezone, time, timeZone, timeZoneAsText } = props;

  return [showTime && time, showTimezone && (timeZoneAsText || timeZone)]
    .filter(Boolean)
    .join(' ');
}
