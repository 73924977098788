import { createSelector } from '@reduxjs/toolkit';
import { getFilterOptions } from '../../../helpers';
import {
  CourseOptionFilterType,
  currencyFilters,
  deliveryTypeFilters,
  languageFilters,
} from '../../../constants';
import { selectCoursePricingOptions } from '../selectCoursePricingOptions';

export const selectCoursePricingFilters = createSelector(
  [selectCoursePricingOptions, (state, section) => section],
  (coursePricingOptions, section = {}) => {
    const MIN_FILTER_OPTIONS = 2;

    const {
      showSettings: {
        showDeliveryTypeFilter,
        showLanguage,
        showLanguageFilter,
        showCurrencyFilter,
      },
    } = section;

    // Get available filter options regarding rows
    const deliveryTypeFilterOptions = showDeliveryTypeFilter
      ? getFilterOptions({
          filters: deliveryTypeFilters,
          rows: coursePricingOptions,
          rowFilterKey: CourseOptionFilterType.DELIVERY_TYPE,
        })
      : [];
    const languageFilterOptions =
      showLanguageFilter && showLanguage
        ? getFilterOptions({
            filters: languageFilters,
            rows: coursePricingOptions,
            rowFilterKey: CourseOptionFilterType.LANGUAGE,
          })
        : [];
    const currencyFilterOptions = showCurrencyFilter
      ? getFilterOptions({
          filters: currencyFilters,
          rows: coursePricingOptions,
          rowFilterKey: CourseOptionFilterType.CURRENCY,
        })
      : [];

    // Prepare filters object
    return {
      [CourseOptionFilterType.DELIVERY_TYPE]: {
        shown:
          showDeliveryTypeFilter &&
          deliveryTypeFilterOptions.length > MIN_FILTER_OPTIONS,
        options: deliveryTypeFilterOptions,
      },
      [CourseOptionFilterType.LANGUAGE]: {
        shown:
          showLanguageFilter &&
          languageFilterOptions.length > MIN_FILTER_OPTIONS,
        options: languageFilterOptions,
      },
      [CourseOptionFilterType.CURRENCY]: {
        shown:
          showCurrencyFilter &&
          currencyFilterOptions.length > MIN_FILTER_OPTIONS,
        options: currencyFilterOptions,
      },
    };
  },
);
