import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { Heading, Paragraph, SiteBuilderModule } from '../sharedComponents';
import {
  HeadingSection,
  StyledHeading,
  Wrapper,
  ContentWrapper,
} from './styles';
import { HTMLtoJSX, renderHTMLString } from '../../../../helpers';

const loadCredsparkScript = () => {
  if (typeof window !== 'undefined') {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://app.credspark.com/assessments/find-your-place-in-the-future-in-foodservice/embed_script.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};

export default function HtmlBlockModule({ section }) {
  const { title, text, titleSize, html } = section;
  const themeConfig = useContext(ThemeContext);

  const { htmlBlockModule } = themeConfig.siteBuilderModules;
  const vStyles = htmlBlockModule.visualStyles.DEFAULT;
  const dStyles = htmlBlockModule.displayStyles.DEFAULT;

  useEffect(() => {
    if (html && html.includes('credsparkQuiz')) {
      loadCredsparkScript();
    }
  }, [html]);

  return (
    <SiteBuilderModule section={section} moduleTheme={{ dStyles, vStyles }}>
      <Wrapper>
        {(title || text) && (
          <HeadingSection>
            <div className="container">
              <div className="row middle-xs">
                <div className="col-xs-12">
                  <StyledHeading>
                    <Heading as={titleSize}>{renderHTMLString(title)}</Heading>
                  </StyledHeading>
                  <Paragraph content={text} />
                </div>
              </div>
            </div>
          </HeadingSection>
        )}
        <ContentWrapper>
          <div className="container">
            <div className="row middle-xs">
              <div className="col-xs-12">{HTMLtoJSX(html)}</div>
            </div>
          </div>
        </ContentWrapper>
      </Wrapper>
    </SiteBuilderModule>
  );
}
