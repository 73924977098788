import uniq from 'lodash/uniq';
import { CoursePricingOption } from '../../../../../../store/features/coursePricing';
import { CourseOptionFilter } from '../../types';
import { CourseOptionAllFilterOption } from '../../constants';

export type getFilterOptionsProps = {
  filters: CourseOptionFilter[];
  rows: CoursePricingOption[];
  rowFilterKey: keyof CoursePricingOption;
};
export function getFilterOptions({
  filters,
  rows,
  rowFilterKey,
}: getFilterOptionsProps): CourseOptionFilter[] {
  const availableFilterOptions = uniq(rows.map((row) => row[rowFilterKey]));

  return filters.filter((filter) => {
    return (
      filter.value === CourseOptionAllFilterOption ||
      availableFilterOptions.includes(filter.value)
    );
  });
}
