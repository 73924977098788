import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers';

export const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.htmlBlockModule)};

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)};
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)};
`;

export const HeadingSection = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles?.elements?.headingSection,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles?.elements?.headingSection,
    )}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles?.elements?.contentWrapper,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles?.elements?.contentWrapper,
    )}
`;

export const StyledHeading = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.heading)};
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.heading)};
`;
