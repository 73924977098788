import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import SharedLink from '../../../../../shared/Link/Link';

export const StyledLink = styled(SharedLink)`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsVStyles?.elements?.link,
    )}
`;
