import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectCoursePricingOptions } from '../../../CourseOptionDisplayModule/context/selectors';
import { OptionsDisplay } from '../../types';
import {
  CardsWrapper,
  CardsSectionTitle,
  StyledCardsList,
  CardsCallToAction,
} from '../../styledComponents';
import { Card } from '../Card';

export type CardsListProps = {
  optionsDisplay: OptionsDisplay;
};

export function CardsList({ optionsDisplay }: CardsListProps) {
  const themeConfig = useContext(ThemeContext);
  const options = useRootSelector((state) =>
    selectCoursePricingOptions(state, optionsDisplay),
  );

  if (options.length === 0) {
    return null;
  }

  const { title, link } = optionsDisplay;

  const optionsDStyles = themeConfig.module.optionsDStyles;
  const alignStyle = themeConfig.module.alignStyle;
  const { xs, sm, offset } =
    optionsDStyles?.alignStyles?.[alignStyle]?.contentGrid || {};

  return (
    <CardsWrapper
      className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${offset}`}
    >
      {title && <CardsSectionTitle>{title}</CardsSectionTitle>}
      <StyledCardsList>
        {options.map((option, index) => (
          <Card key={index} option={option} />
        ))}
      </StyledCardsList>
      <CardsCallToAction ctaConfig={link} />
    </CardsWrapper>
  );
}
