import styled from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

export const CardsSectionTitle = styled.h3`
  margin-bottom: 8px;

  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    margin-bottom: 16px;
  }

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.optionsVStyles?.elements?.title,
    )};
`;
